<template>
  <div>
    <ul class="vouchers" v-if="vouchers || balance">
      <li class="flex-container v-li" v-if="vouchers && vouchers.length != 0">
        <img class="icon" src="@image/order/order_vouchers.png" />
        <span class="title flex1">优惠券</span>
        <i class="price" @click="showCouponShow = true">
          {{
            thisVouchers && thisVouchers.voucher_price
              ? `-￥${thisVouchers.voucher_price}`
              : "不使用"
          }}
        </i>
        <img
          class="more"
          src="@image/goods/review_more.png"
          @click="showCouponShow = true"
        />
      </li>
      <li class="flex-container v-li" v-if="balance">
        <img class="icon" src="@image/order/order_price.png" />
        <span class="title">零钱支付</span>
        <i class="balance flex1">(余额:{{ balance }})</i>
        <van-switch
          @change="changeChecked"
          active-color="#f65a21"
          v-model="checked"
          size="20px"
          class="switch"
        />
      </li>
    </ul>

    <van-popup
      v-model="showCouponShow"
      round
      closeable
      position="bottom"
      style="height: 80%; "
    >
      <oCoupon
        :vouchers="vouchers"
        :boo.sync="showCouponShow"
        @enter="getSelectCoupon"
      ></oCoupon>
    </van-popup>
  </div>
</template>

<script>
import { Switch, Popup, Toast } from "vant";
import oCoupon from "./components/coupon";
export default {
  props: {
    vouchers: {},
    balance: {},
    balancePayState: {},
    totalPrice: {},
    voucher: {},
  },
  components: {
    vanSwitch: Switch,
    oCoupon,
    vanPopup: Popup,
  },
  mounted() {},
  watch: {
    vouchers() {
      if (this.vouchers && this.vouchers.length != 0) {
        this.thisVouchers = this.vouchers[0];
        this.$emit("update:voucher", this.vouchers[0]);
      }
    },
  },
  created() {},
  methods: {
    changeChecked(val) {
      let vo = this.voucher?.voucher_price || 0;
      console.log(
        +parseFloat(this.balance).toFixed(2) <
          +parseFloat(this.totalPrice).toFixed(2) - vo
      );
      if (
        +parseFloat(this.balance).toFixed(2) <
        +parseFloat(this.totalPrice).toFixed(2) - vo
      ) {
        Toast("零钱余额不足不能使用~");
        this.checked = false;
        this.$emit("update:balancePayState", false);
      } else {
        this.$emit("update:balancePayState", val);
      }
    },
    getSelectCoupon(item) {
      this.thisVouchers = item;
      this.$emit("update:voucher", item);
    },
  },
  data() {
    return {
      thisVouchers: {},
      showCouponShow: false,
      checked: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.vouchers {
  background: #fff;
  width: rem(710);
  margin: 0 auto;
  border-radius: rem(20);
  padding: rem(20);
  box-sizing: border-box;
  .v-li {
    align-items: center;
    justify-content: center;
    margin-bottom: rem(30);
    &:last-of-type {
      margin-bottom: 0;
    }
    .icon {
      width: rem(30);
    }
    .switch {
      // margin-right: rem(20);
    }
    .balance {
      color: #a7a7a9;
      // font-size: rem(20);
      margin-left: rem(20);
      margin-top: rem(4);
    }
    .title {
      margin-left: rem(10);
      font-size: rem(28);
      color: #232323;
    }
    .price {
      color: #f65a21;
      font-size: rem(28);
      margin-right: rem(20);
    }
    .more {
      width: rem(23);
    }
  }
}
</style>
