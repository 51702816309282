<template>
  <ul id="gift">
    <div class="gift_num_tip">
      <!-- <span>赠品</span> -->

      <span v-if="gift && gift[0]">
        <template
          v-if="
            gift[0].start_time != 0 &&
              gift[0].end_time != 0 &&
              nowTime > gift[0].end_time * 1000
          "
        >
          <oTime
            :times="gift[0].end_time"
            style="display:inline-block;"
          ></oTime>
          <i> 赠品即将结束，</i>
        </template>
        <template v-else>赠品</template>数量有限，先到先得
      </span>
    </div>
    <li class="gift flex-container" v-for="g in gift" :key="g.gift_id">
      <img
        :src="
          `http://image.shop.19jiang.cn/home/store/goods/2/${g.gift_goodsimage}`
        "
      />
      <h2>
        <i>赠品</i>
        {{ g.gift_goodsname }}
      </h2>
      <span class="flex1">x{{ g.gift_amount }}</span>
    </li>
  </ul>
</template>

<script>
import oTime from "../time";
export default {
  components: {
    oTime,
  },
  props: {
    gift: {},
  },
  data() {
    return {
      //  +
      nowTime: new Date().getTime() + 24 * 3 * 60 * 60 * 1000,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#gift {
  margin-top: rem(20);
}
.gift_num_tip {
  span {
    color: #f21a4b;
    font-size: rem(20);
  }
}
.gift {
  padding: rem(10) rem(30);
  i {
    display: inline-block;
    width: rem(46);
    font-size: rem(18);
    // height: rem(20);
    // line-height: rem(20);
    text-align: center;
    color: #f21a4b;
    border: 1px solid #f21a4b;
  }
  img {
    width: rem(80);
    height: rem(80);
  }
  h2 {
    margin-left: rem(24);
    width: rem(450);
    font-size: rem(22);
  }
  span {
    display: block;
    text-align: right;
    font-size: rem(20);
  }
}
</style>
